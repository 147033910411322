<template>
    <div ref="container">
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('payment_info')"
                        :actions="actions"
                        :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('payment_info')"
                              :actions="actions"
                              :isFilter="false"/>
            </template>
            <div v-if="data!=null && data.customer!=null">
                <div class="border px-5 pt-5 mb-5 rounded">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="row">
                                <div class="col-12 col-md-6 mb-3"><label
                                    class="col-form-label mr-1 p-0">
                                    {{ $t('student_number') }}:</label>{{ data.customer.number }}
                                </div>
                                <div class="col-12 mb-3"><label
                                    class="col-form-label mr-1 p-0">{{
                                        $t('name_surname')
                                    }}:</label>{{ data.customer.name }}
                                    {{ data.customer.surname }}
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="col-form-label mr-1 p-0">
                                        {{ $t('student_group') }}:
                                    </label>
                                    {{ data.customer.group }}
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <b-row>
                                        <b-col sm="auto">
                                            <b-badge
                                                :variant="(data.customer.payment_status=='E'?'success':'danger') + ' text-uppercase'">
									<span class="d-flex align-items-center">
										<i :class="'ri-'+(data.customer.payment_status=='E'?'check':'close')+'-line mr-1 top-minus-1 font-size-base'"></i>
                                        {{ this.$t('payment_status_' + data.customer.payment_status) }}
									</span>
                                            </b-badge>
                                        </b-col>
                                        <b-col sm="auto">
                                            <b-button variant="link p-0" size="xs" @click="checkApproval"
                                                      v-show="!checkPayment">
                                                <div class="d-flex align-items-center">
                                                    <b-spinner label="Spinning" class="mr-3" small
                                                               v-show="checkSpinner"></b-spinner>
                                                    {{ $t('check_payment_approval').toUpper() }}
                                                </div>
                                            </b-button>
                                            <b-button variant="link p-0" size="xs" @click="checkApproval"
                                                      v-show="checkPayment">
                                        <span v-show="checkPayment">
                                    <span v-show="paymentControl" class="text-success"><span
                                        class="d-flex align-items-center"><i class="ri-check-line mr-2 ri-lg"
                                                                             v-show="!checkSpinner"></i><b-spinner
                                        label="Spinning" class="mr-3 text-primary" small
                                        v-show="checkSpinner"></b-spinner>{{ $t('payment_approval_successful') }}</span></span>
                                    <span v-show="!paymentControl" class="text-danger"><span
                                        class="d-flex align-items-center"><i class="ri-close-line mr-2 ri-lg"
                                                                             v-show="!checkSpinner"></i><b-spinner
                                        label="Spinning" class="mr-3 text-primary" small
                                        v-show="checkSpinner"></b-spinner>{{
                                            $t('payment_approval_failed')
                                        }}</span></span>
                                  </span>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="row">
                                <div class="col-12 mb-3"><label
                                    class="col-form-label mr-1 p-0">{{
                                        $t('faculty')
                                    }}:</label>{{ data.customer.faculty_name }}
                                </div>
                                <div class="col-12 mb-3"><label
                                    class="col-form-label mr-1 p-0">{{
                                        $t('program')
                                    }}:</label>{{ data.customer.program_name }}
                                    ({{ data.customer.program_code }})
                                </div>
                                <div class="col-12 mb-3"><label
                                    class="col-form-label mr-1 p-0">{{
                                        $t('class')
                                    }}:</label>{{ data.customer.class_name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-5 d-flex" v-if="data!=null && data.customer!=null">
                <div class="row">
                    <div class="col-auto">
                        <b-button variant="outline-primary" @click="findStudent"><span
                            class="d-flex"><i class="ri-add-line mr-2"></i>{{ $t('find_student').toUpper() }}</span>
                        </b-button>
                    </div>
                    <div class="col-auto" v-if="data && data.offsets.length>0">
                        <b-button variant="outline-primary" @click="offsetDetail"><span
                            class="d-flex"><i class="ri-add-line mr-2"></i>{{ $t('offsets_detail').toUpper() }}</span>
                        </b-button>
                    </div>
                    <div class="col-auto" v-if="student_program_id && student_program_id>0">
                        <b-button variant="outline-primary" @click="showOtherPayments()">
                            <span class="d-flex"><i class="ri-add-line mr-2"></i>{{
                                    $t('other_payments').toUpper()
                                }}</span>
                        </b-button>
                    </div>
                </div>
            </div>
            <b-tabs content-class="py-5 position-relative"
                    v-if="data!=null && data.periods!=null && data.periods.length>0">
                <div class="tabs-bottom-line"></div>
                <b-tab v-for="period in data.periods" v-bind:key="period" :active="period===data.active_period">
                    <template #title><span class="d-flex align-items-center"><i
                        class="ri-calendar-line top-plus-1 mr-2"></i>{{
                            (data.period_alias && data.period_alias[period]) ? data.period_alias[period] : period
                        }}</span></template>

                    <div class="row" v-if="checkPermission('scholarship_index') && data.scholarships!=null && data.scholarships[period]!=null">
                        <div class="col-auto mb-3" v-if="data.discounts[period]">
                            <div class="badge badge-info">
                                <b class="font-size-base mr-1">{{ $t('total_discount_rate') }}:</b><span
                                class="badge badge-success">%{{
                                    parseFloat(
                                        data.discounts[period].discount_rate)
                                }}</span>
                            </div>
                        </div>
                        <div class="col-auto mb-3"
                             v-if="data.discounts[period] && parseFloat(data.discounts[period].discount_amount)>0">
                            <div class="badge badge-info">
                                <b class="font-size-base mr-1">{{ $t('total_discount_amount') }}:</b><span
                                class="badge badge-success">{{
                                    parseFloat(
                                        data.discounts[period].discount_amount)
                                }}</span>
                            </div>
                        </div>
                        <div class="col-auto mb-3">
                            <b-button variant="outline-primary" @click="$bvModal.show('scholarshipDetail'+period)">
                                {{ toUpperCase('scholarship_detail') }}
                            </b-button>
                        </div>
                    </div>

                    <CommonModal :id="'scholarshipDetail'+period" size="lg">
                        <template v-slot:CommonModalTitle>
                            <div>{{ $t('scholarships').toUpper() }}</div>
                        </template>
                        <template v-slot:CommonModalContent>
                            <b-table
                                v-if="data.scholarships!=null && data.scholarships[period]!=null"
                                bordered
                                striped
                                responsive
                                :items="data.scholarships[period]"
                                :fields="scholarshipFields"
                                class="mb-5 table-dropdown no-scrollbar border rounded">
                            </b-table>
                        </template>
                    </CommonModal>

                    <div v-for="plan in data.payment_plans[period]" v-bind:key="plan.id" :accesskey="plan.printId='0'">
                        <h6 class="text-uppercase mb-4">{{ plan.product.name.toUpper() }}</h6>
                        <b-table
                            bordered
                            striped
                            responsive
                            :items="[plan]"
                            :fields="priceFields"
                            class="mb-4 table-dropdown no-scrollbar border rounded">
                            <template #cell(gross_price)="row">
                                <div v-if="row.item.credits>0">
                                    {{ row.item.gross_price | formatToCurrency(row.item.currency) }}
                                    ({{ row.item.price / row.item.credits | formatToCurrency(row.item.currency) }} x
                                    {{ row.item.credits }})
                                </div>
                                <div v-else>
                                    {{ row.item.gross_price | formatToCurrency(row.item.currency) }}
                                </div>

                            </template>
                            <template #cell(price)="row">
                                <div
                                    :class="row.item.is_cash==false && row.item.payments.length>0?'badge badge-danger':''">
                                    {{ row.item.price | formatToCurrency(row.item.currency) }}
                                </div>
                            </template>
                            <template #cell(cash_price)="row">
                                <div :class="row.item.is_cash && row.item.payments.length>0?'badge badge-danger':''">
                                    {{ row.item.cash_price | formatToCurrency(row.item.currency) }}
                                </div>
                            </template>
                        </b-table>
                        <b-table
                            v-if="plan.payments.length>0"
                            bordered
                            striped
                            responsive
                            :items="plan.payments"
                            :fields="paymentFields"
                            :tbody-tr-class="paymentRowClass"
                            show-empty
                            class="mb-5 table-dropdown no-scrollbar border rounded">
                            <template #cell(due_date)="row">
                                <div v-if="row.item.parent_id!=null">
                                </div>
                                <div v-else>
                                    {{ row.item.due_date | dateFormat }}
                                </div>
                            </template>
                            <template #cell(amount)="row">
                                <span v-if="row.item.parent_id==null">{{
                                        row.item.amount | formatToCurrency(plan.currency)
                                    }}</span>
                            </template>
                            <template #cell(payment_type_name)="row">
                                {{ row.item.payment_type_name }}
                                <span
                                    v-if="row.item.payment_type_code=='ENDORSED'"><br>{{
                                        row.item.account_number
                                    }}</span>
                                <span v-if="row.item.is_cancelled"
                                      class="badge badge-danger right">{{ toUpperCase('cancelled') }}</span>
                            </template>
                            <template slot="bottom-row">
                                <td>
                                    <span class="badge badge-danger" style="width: 100%">{{
                                            toUpperCase(plan.is_cash ? 'cash' : 'with_installment')
                                        }}</span>
                                </td>
                                <td><b>
                                    {{ plan.payments_sum_amount | formatToCurrency(plan.currency) }}</b></td>
                                <td><b>{{ plan.paid | formatToCurrency(plan.currency) }}</b></td>
                                <td colspan="4"><b>{{ $t('balance') }}:</b> <b :class="plan.balance<0?'text-danger':''">{{
                                        plan.balance | formatToCurrency(plan.currency)
                                    }}</b>
                                </td>
                            </template>
                        </b-table>
                        <b-table
                            v-if="plan.refunds.length>0"
                            bordered
                            striped
                            responsive
                            :items="plan.refunds"
                            :fields="refundFields"
                            show-empty
                            class="mb-5 table-dropdown no-scrollbar border rounded">
                            <template #cell(amount)="row">
                                {{
                                    (row.item.related_id || row.item.is_offset) ? '' : formatToCurrency(row.item.amount, 'amount', plan)
                                }}
                            </template>
                            <template #cell(cancellation_amount)="row">
                                {{
                                    (row.item.related_id && !row.item.is_offset) ? formatToCurrency(row.item.amount, 'amount', plan) : ''
                                }}
                            </template>
                            <template #cell(offset_amount)="row">
                                {{ row.item.is_offset ? formatToCurrency(row.item.amount, 'amount', plan) : '' }}
                            </template>
                        </b-table>
                        <div class="d-flex mb-3" v-if="plan.refunds.length>0">
                            <div class="mr-3">
                                <label class="col-form-label mr-1 p-0">{{ $t('price') }}:</label>
                                <b>{{
                                        plan.price | formatToCurrency(plan.currency)
                                    }}</b>
                            </div>

                            <div class="mr-3">
                                <label class="col-form-label mr-1 p-0">{{ $t('paid') }}:</label>
                                <b>{{
                                        plan.paid | formatToCurrency(plan.currency)
                                    }}</b>
                            </div>

                            <div class="mr-3">
                                <label class="col-form-label mr-1 p-0">{{ $t('refund') }}:</label>
                                <b>{{
                                        plan.sum_of_refunds | formatToCurrency(plan.currency)
                                    }}</b>
                            </div>

                            <div class="mr-3">
                                <label class="col-form-label mr-1 p-0">{{ $t('cancellation') }}:</label>
                                <b>{{
                                        plan.sum_of_cancellations | formatToCurrency(plan.currency)
                                    }}</b>
                            </div>
                            <div class="mr-3">
                                <label class="col-form-label mr-1 p-0">{{ $t('finance_offset') }}:</label>
                                <b>{{
                                        plan.offset_total | formatToCurrency(plan.currency)
                                    }}</b>
                            </div>

                            <div class="mr-3">
                                <label class="col-form-label mr-1 p-0">{{ $t('balance') }}:</label>
                                <b :class="plan.net_balance<0?'text-danger':''">{{
                                        plan.net_balance | formatToCurrency(plan.currency)
                                    }}</b>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-12 d-flex align-items-center mb-4">
                                <div class="row">
                                    <div class="col-auto" v-if="data.customer.group=='YU'">
                                        <b-button variant="outline-primary mr-3" @click="createProformaInvoice(plan.id)"><span
                                            class="d-flex"><i
                                            class="ri-add-line mr-2"></i>{{ $t('create_proforma_invoice').toUpper() }}</span>
                                        </b-button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>
            <CommonModal ref="findStudentModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ toUpperCase('find_student').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row mb-3">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <b-form-group>
                                <label class="col-form-label pt-0">{{ $t('student_number') }}</label>
                                <b-form-input v-on:keyup.enter="searchStudent"
                                              v-model="findStudentForm.student_number"></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <b-form-group>
                                <label class="col-form-label pt-0">{{ $t('name') }}</label>
                                <b-form-input v-on:keyup.enter="searchStudent"
                                              v-model="findStudentForm.name"></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <b-form-group>
                                <label class="col-form-label pt-0">{{ $t('surname') }}</label>
                                <b-form-input v-on:keyup.enter="searchStudent"
                                              v-model="findStudentForm.surname"></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-12">
                            <b-button type="button" @click="searchStudent" variant="primary">{{
                                    $t('search')
                                }}
                            </b-button>
                        </div>
                    </div>
                    <div class="row"
                         v-if="studentSearchResult && (studentSearchResult.students || studentSearchResult.registrations)">
                        <table class="table table-bordered">
                            <tr>
                                <th>{{ toUpperCase('student_number') }}</th>
                                <th>{{ toUpperCase('name_surname') }}</th>
                                <th>{{ toUpperCase('program_name') }}</th>
                                <th></th>
                                <th class="w-40"></th>
                            </tr>
                            <tr v-for="student in studentSearchResult.students">
                                <td>{{ student.student_number }}</td>
                                <td>{{ student.name }} {{ student.surname }}</td>
                                <td>{{ student.program_name }} ({{ student.major_type_name }} -
                                    {{ student.student_status_name }})
                                </td>
                                <td>{{ $t('student') }}</td>
                                <td>
                                    <b-button variant="outline-primary"
                                              @click="openStudent(student.student_program_id)">
                                        {{ $t('select_it') }}
                                    </b-button>
                                </td>
                            </tr>
                            <tr v-for="student in studentSearchResult.registrations">
                                <td>{{ student.student_number }}</td>
                                <td>{{ student.name }} {{ student.surname }}</td>
                                <td>{{ student.program_name }} ({{ student.academic_year }})</td>
                                <td>{{ $t('registration') }}</td>
                                <td>
                                    <b-button variant="outline-primary mr-3"
                                              @click="openRegistration(student.registration_id)">
                                        {{ $t('select_it') }}
                                    </b-button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </template>
            </CommonModal>
            <CommonModal ref="offsetDetailModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('offsets_detail') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row" v-if="data && data.offsets">
                        <b-table
                            v-if="data.offsets.length>0"
                            bordered
                            striped
                            responsive
                            :items="data.offsets"
                            :fields="offsetFields"
                            show-empty
                            class="mb-5 table-dropdown no-scrollbar border rounded">
                            <template #cell(period)="row">
                                {{ row.item.amount > 0 ? row.item.period : '' }}
                            </template>
                            <template #cell(amount)="row">
                                <span
                                    :class="row.item.amount<0?'text-danger':''">{{
                                        row.item.amount | formatToCurrency(row.item.currency)
                                    }}</span>
                            </template>
                            <template #cell(balance)="row">
                                {{ row.item.balance ? formatToCurrency(row.item.balance, 'amount', row.item) : '' }}
                            </template>
                        </b-table>
                        <div class="d-flex mb-3" v-if="data.offsets.length>0 && data.offsets_total">
                            <div class="mr-3">
                                <label class="col-form-label mr-1 p-0">{{ $t('offset_total') }}:</label>
                                <b>{{
                                        data.offsets_total | formatToCurrency(data.offset_currency)
                                    }}</b>
                            </div>

                            <div class="mr-3">
                                <label class="col-form-label mr-1 p-0">{{ $t('paid') }}:</label>
                                <b>{{
                                        data.offset_payments_total | formatToCurrency(data.offset_currency)
                                    }}</b>
                            </div>

                            <div class="mr-3">
                                <label class="col-form-label mr-1 p-0">{{ $t('balance') }}:</label>
                                <b>{{
                                        data.offset_balance | formatToCurrency(data.offset_currency)
                                    }}</b>
                            </div>

                        </div>
                    </div>
                </template>
            </CommonModal>
            <CommonModal id="otherPaymentsModal" size="xl">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('other_payments') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row" v-if="otherPayments">
                        <b-table
                            v-if="otherPayments.length>0"
                            bordered
                            striped
                            responsive
                            :items="otherPayments"
                            :fields="otherPaymentFields"
                            show-empty
                            class="mb-5 table-dropdown no-scrollbar border rounded">
                            <template #cell(amount)="row">
                                <span
                                    :class="row.item.amount<0?'text-danger':''">{{
                                        row.item.amount | formatToCurrency(row.item.currency)
                                    }}</span>
                            </template>
                        </b-table>
                    </div>
                </template>
            </CommonModal>
            <CommonModal id="proformaInvoiceModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('proforma_invoices').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ProformaInvoiceList
                        v-if="data && data.customer && showProformaInvoice"
                        :customer_id="data.customer.id" :student_program_id="student_program_id" :registration_id="registration_id">

                    </ProformaInvoiceList>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from '@/layouts/AppLayout';
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import CommonModal from '@/components/elements/CommonModal.vue';
import CustomerService from '@/services/CustomerService';
import OtherPaymentService from "@/services/OtherPaymentService";
import StudentService from '@/services/StudentService';
import ProformaInvoice from '@/services/ProformaInvoiceService';
import ProformaInvoiceList from "@/modules/proformaInvoice/page/List";
import qs from "qs";

export default {
    name: 'Payments',
    props: {},
    components: {
        Header,
        HeaderMobile,
        AppLayout,
        CommonModal,
        ProformaInvoiceList
    },
    data() {
        return {
            student_program_id: this.$route.params.student_program_id,
            registration_id: this.$route.params.student_program_id,
            data: null,
            findStudentForm: {},
            studentSearchResult: null,
            disabled: false,
            updating: false,
            size: 'lg',
            selected: null,
            checkSpinner: false,
            paymentControl: false,
            checkPayment: false,
            date: new Date(),
            masks: {
                input: 'DD-MM-YYYY'
            },
            priceFields: [
                {
                    key: 'gross_price',
                    label: this.toUpperCase('gross_price'),
                    sortable: true,
                    formatter: this.formatToCurrency
                },
                {
                    key: 'price',
                    label: this.toUpperCase('with_installment'),
                    sortable: true,
                    formatter: this.formatToCurrency
                },
                {
                    key: 'cash_price',
                    label: this.toUpperCase('cash'),
                    sortable: true,
                    formatter: this.formatToCurrency
                },
                {
                    key: 'due_date',
                    label: this.toUpperCase('due_date'),
                    sortable: true,
                    formatter: this.formatDate
                },
            ],
            scholarshipFields: [
                {
                    key: 'scholarship_type.name',
                    label: this.toUpperCase('scholarship_type'),
                    sortable: true
                },
                {
                    key: 'discount_rate',
                    label: this.toUpperCase('discount_rate'),
                    sortable: true
                },
                {
                    key: 'discount_amount',
                    label: this.toUpperCase('discount_amount'),
                    sortable: true
                },
                {key: 'dropdownTable', label: '#'}
            ],
            paymentFields: [
                {
                    key: 'due_date',
                    label: this.toUpperCase('due_date'),
                    formatter: this.formatDate,
                    thClass: 'width-150'
                },
                {
                    key: 'amount',
                    label: this.toUpperCase('amount_to_be_paid'),
                    formatter: this.formatToCurrency
                },
                {
                    key: 'amount_paid',
                    label: this.toUpperCase('amount_paid'),
                    formatter: this.formatToCurrency
                },
                {
                    key: 'balance',
                    label: this.toUpperCase('balance'),
                    formatter: this.formatToCurrency
                },
                {
                    key: 'payment_date',
                    label: this.toUpperCase('payment_date'),
                    formatter: this.formatDate,
                    thClass: 'width-150'
                },
                {
                    key: 'transaction_date',
                    label: this.toUpperCase('transaction_date'),
                    formatter: this.formatDate,
                    thClass: 'width-150'
                },
            ],

            refundFields: [
                {
                    key: 'refund_date',
                    label: this.toUpperCase('refund_date'),
                    formatter: this.formatDate,
                    thClass: 'width-150'
                },
                {
                    key: 'amount',
                    label: this.toUpperCase('refund_amount')
                },
                {
                    key: 'cancellation_amount',
                    label: this.toUpperCase('cancellation_amount')
                },
                {
                    key: 'offset_amount',
                    label: this.toUpperCase('offset_amount')
                },
            ],

            offsetFields: [
                {
                    key: 'period',
                    label: this.toUpperCase('period')
                },
                {
                    key: 'transaction_date',
                    label: this.toUpperCase('offset_date'),
                    formatter: this.formatDate,
                    thClass: 'width-150'
                },
                {
                    key: 'amount',
                    label: this.toUpperCase('amount'),
                    formatter: this.formatToCurrency
                },
                {
                    key: 'balance',
                    label: this.toUpperCase('balance')
                },
                {
                    key: 'explanation',
                    label: this.toUpperCase('explanation')
                },
            ],
            otherPayments: null,
            otherPaymentFields: [
                {
                    key: 'period',
                    label: this.toUpperCase('period')
                },
                {
                    key: 'product_name',
                    label: this.toUpperCase('price_type'),
                },
                {
                    key: 'amount',
                    label: this.toUpperCase('amount'),
                },
                {
                    key: 'due_date',
                    label: this.toUpperCase('due_date'),
                    formatter: this.formatDate,
                    thClass: 'width-150'
                },
                {
                    key: 'payment_date',
                    label: this.toUpperCase('payment_date'),
                    formatter: this.formatDate,
                    thClass: 'width-150'
                },
                {
                    key: 'explanation',
                    label: this.toUpperCase('explanation')
                },
            ],
            actions:null
        }
    },
    created() {
        this.loadData();
    },
    watch: {},
    methods: {
        openStudent(id) {
            if (this.student_program_id == id) {
                return;
            }
            this.student_program_id = id;
            this.registrationId = 0;
            this.loadData();
            this.$router.push('/customers/payments/view/student/' + id);
            this.$refs.findStudentModal.$refs.commonModal.hide();
        },
        openRegistration(id) {
            if (this.registrationId == id) {
                return;
            }
            this.student_program_id = 0;
            this.registrationId = id;
            this.loadData();
            this.$router.push('/customers/payments/view/registration/' + id);
            this.$refs.findStudentModal.$refs.commonModal.hide();
        },
        loadData() {
            if (this.student_program_id > 0) {
                CustomerService.getStudentPayments(this.student_program_id).then(this.dataCallback).catch(e => {
                    this.showErrors(e);
                });
            } else if (this.registrationId > 0) {
                CustomerService.getRegistrationPayments(this.registrationId).then(this.dataCallback).catch(e => {
                    this.showErrors(e);
                });
            }
        },
        dataCallback(response) {
            this.data = response.data.data;
            if (this.data.customer.error) {
                this.$swal.fire({
                    icon: 'error',
                    text: this.$t(this.data.customer.error),
                })
            }
            this.actions = [
                {
                    text: this.$t('proforma_invoices'),
                    permission: 'proformainvoice_index',
                    callback: () => {
                        this.showProformaInvoice=true;
                        this.$bvModal.show('proformaInvoiceModal');
                    },
                    show: ()=>{
                        return this.data.customer.group=='YU';
                    }
                },
                {
                    text: this.$t('leave_of_absence'),
                    permission: 'paymentplan_createleaveofabsence',
                    callback: () => {
                        this.$bvModal.show('leaveOfAbsenceModal');
                    },
                    show: ()=>{
                        return this.student_program_id>0 &&
                            this.data.customer.active_semester &&
                            this.data.customer.active_semester.semester_status=='103';
                    }
                },
            ];
        },
        findStudent() {
            this.$refs.findStudentModal.$refs.commonModal.show();
        },
        searchStudent() {
            if (this.findStudentForm.student_number || (this.findStudentForm.name && this.findStudentForm.surname) || this.findStudentForm.surname) {
                StudentService.studentSearch({params: this.findStudentForm})
                    .then(response => {
                        this.studentSearchResult = response.data.data;
                    });
            }
        },

        checkApproval() {
            this.checkSpinner = true
            if (this.student_program_id > 0) {
                CustomerService.approveStudent(this.data.customer.number, this.student_program_id)
                    .then(response => {
                        if (response.data.success === true) {
                            this.paymentControl = true;
                            this.toast(response.data);
                            this.data.customer.payment_status = 'E';
                        } else {
                            this.paymentControl = false;
                            let message = this.$t('api.' + response.data.message);
                            if (typeof response.data.errors != 'undefined') {
                                message += '\n' + response.data.errors[0];
                            }
                            this.$toast.error(message);
                            this.data.customer.payment_status = 'H';
                        }
                    })
                    .catch(e => {
                        this.paymentControl = false;
                        let message = this.$t('api.' + e.data.message);
                        if (typeof e.data.errors != 'undefined') {
                            message += '\n' + e.data.errors[0];
                        }
                        this.$toast.error(message);
                    })
                    .finally(() => {
                        this.checkSpinner = false
                        this.checkPayment = true
                    });
            } else if (this.registrationId > 0) {
                CustomerService.approveRegistration(this.data.customer.number, this.registrationId, this.data.customer.registration_semester.academic_year)
                    .then(response => {
                        if (response.data.success === true) {
                            this.paymentControl = true;
                            this.toast(response.data);
                        } else {
                            this.paymentControl = false;
                            this.$toast.error(this.$t('api.' + response.data.message));
                        }
                    })
                    .catch(e => {
                        this.paymentControl = false;
                        let message = this.$t('api.' + e.data.message);
                        if (typeof e.data.errors != 'undefined') {
                            message += '\n' + e.data.errors[0];
                        }
                        this.$toast.error(message);
                    })
                    .finally(() => {
                        this.checkSpinner = false
                        this.checkPayment = true
                    });
            }
        },

        formatToCurrency(value, key, item) {
            return this.$options.filters.formatToCurrency(value, item.currency);
        },
        formatDate(value) {
            return this.$options.filters.dateFormat(value);
        },

        offsetDetail() {
            this.$refs.offsetDetailModal.$refs.commonModal.show()
        },
        paymentRowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.parent_id) return 'sub-payments'
        },
        showOtherPayments() {
            const config = {
                params: {
                    filter: {
                        customer_id: this.data.customer.id,
                        student_program_id: this.student_program_id,
                    },
                    limit: -1,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }
            OtherPaymentService.getAll(config)
                .then(response => {
                    this.otherPayments = response.data.data;
                    this.$bvModal.show('otherPaymentsModal');
                });
        },
        createProformaInvoice(payment_plan_id){
            this.$swal.fire({
                text: this.$t('are_you_sure_to_create_a_new_proforma_invoice'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        ProformaInvoice.store({
                            payment_plan_id: payment_plan_id,
                            customer_id: this.data.customer.id,
                            student_program_id: this.student_program_id,
                            //registration_id: this.registration_id
                        }).then(response=>{
                            this.toast(response.data)
                            this.loadData();
                        }).catch(e=>{
                            this.showErrors(e);
                        });
                    }
                })

        },
    }
}
</script>
<style scoped>
* >>> .sub-payments {
    background-color: #e4f5ee !important;
    font-weight: 500;
}

* >>> button {
    white-space: nowrap;
}

* >>> .table-bordered thead tr th {
    background-color: #005baa !important;
    color: #ffffff;
}

* >>> .btn-xs {
    line-height: 24px !important;
    height: 24px !important;
}
</style>
